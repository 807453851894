.flip-card {
  width: '100%';
  height: '100%';
  perspective: 1000px;
}

/* This container is needed to position the front and back side */
.flip-card .flip-card.inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card.inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card .flip-card.front, .flip-card .flip-card.back {
  position: absolute;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the back side */
.flip-card .flip-card.back {
  transform: rotateY(180deg);
}

.hover .hover.back{
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.438);
}

.hover:hover .hover.back{
  display: block;
}

input[value=""]::-webkit-datetime-edit{ color: transparent; }
input:focus::-webkit-datetime-edit{ color: #000; }